header {
    display: flex;
    justify-content: center;
    width: 100%;
    top: 0;
    z-index: 99999;
    background-color: #000;

    @media (min-width: 1200px){
        position: sticky;
    }
    
    &::after {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3)   
    }

    .user-balance-box{
        @media (min-width: 0px) and (max-width: 767px){
            padding: 0px 5px;
        }
    }

    .amount-wrap{
        & > .amount{
            .user-balance-box{
                @media (min-width: 0px) and (max-width: 767px){
                    display: none;
                }
            }
        }
    }
            
    .lang {
        height: 44px;
        display: none;
        align-items: center;
        justify-content: center;
        background-color: #131313;
        border-radius: 15px;
        padding: 20px 0;
        color: #ffffff;
        width: 120px;
        font-size: 18px;
        font-weight: 700;
        
        @media(min-width: 1180px) {
            display: flex;
        }

        @media(min-width: 1600px) {
            height: 50px;
        }

        img {
            margin-left: 20px;
        }
    }


    .inner-header {
        top: 0;
        left: 0;
        width: 100%;
        max-height: 100px;
        color: white;
        display: flex;
        align-items: center;
        z-index: 9999;
        
        @media (min-width: 0px) and (max-width: 767px){
            padding: 10px 15px;
            height: 55px;
        }
        @media (min-width: 768px){
            padding: 12.5px 25px;
            height: 73px;
        }
        @media(min-width: 1180px) {
            flex-wrap: wrap;
        }

        .site-logo{
            width: 120px;
            height: auto;
            
            .letter {
                fill: white;
            }
            
            .circle{ 
                fill: var(--default);
                transition: all .5s ease-out;
            }

            &.casino .circle{ fill: var(--casino); }
            &.live-casino .circle{ fill: var(--live-casino); }
            &.sports .circle{ fill: var(--sports); }
        }

        .main-logo{
            display: none;
            flex: 0 0 120px;
            width: 120px;
            @media(min-width: 1180px) {
                display: flex;
            }
        }

        .mobile-logo {
            display: block;
            margin-right: 15px;
            img{
                width: 24px;
                height: auto;
                float: left;
            }

            @media(min-width: 1180px) {
                display: none;
            }
        }
    
        .login-wrap {
            display: flex;
            align-items: center;
            margin-left: auto;

            .buttons {
                display: flex;
                .btn{
                    margin-left: 10px;
                }
            }
    
            .service-menu {
                display: flex;
                align-items: center;
                color: black;
                position: relative;
                
                .balance {
                    min-height: 40px;
                    display: flex;
                    background-color: #131313;
                    border-radius: 15px;
                    margin-right: 8px;
                    
                    @media(min-width: 1600px) {
                        margin-right: 15px;
                        min-height: 50px;
                    }
    
                    .amount-wrap {
                        display: flex;
                        align-items: center;
    
                        .amount {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            color: #ffffff;
                            padding-left: 8px;
                            font-size: 14px;
                            font-weight: 700;
                            
                            @media(min-width: 1180px) {
                                padding-left: 20px;
                                font-size: 16px;
                            }

                            .toggleBalance {
                                cursor: pointer;
                                display: block;
                                margin-left: 10px;
                                width: 30px;
                                @media (min-width: 0px) and (max-width: 767px){
                                    display: none;   
                                }
                            }
        
                            img.toggleDropdown {
                                cursor: pointer;
                                width: 20px;
                                margin: 0 10px;
                                
                                @media (min-width: 0px) and (max-width: 767px){
                                    width: 20px;
                                    margin: 0 5px;
                                    margin-right: 10px;  
                                }
                                
                                @media(min-width: 1180px) {
                                    margin-left: 5px;
                                    margin-right: 10px;
                                    width: 36px;
                                    padding: 15px 10px;
                                }
                            }
                        }
                    }
    
                    .deposit-wrap {
                        padding-left: 0;
                        .deposit {
                            .label {
                                display: none;
                                @media(min-width: 1180px) {
                                    display: block;
                                }
                            }
                        }
                    }
                }
    
                .user-wrap {
                    display: flex;
                    align-items: center;
                    position: relative;
                    .user {
                        min-height: 40px;
                        display: flex;
                        align-items: center;
                        background-color: #131313;
                        border-radius: 15px;
                        transition: all .2s ease-out;
                        cursor: pointer;

                        @media (min-width: 0px) and (max-width: 767px){
                            padding: 5px;
                            border-radius: 100%;                           
                            background-color: none;
                        }
                        @media (min-width: 768px){
                            padding: 3px 8px;   
                        }

                        .user-pic {
                            transition: all .2s ease-out;
                            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0);
                            border-radius: 50%;
                            @media (min-width: 0px) and (max-width: 767px){
                                width: 32px;
                            }
                            @media (min-width: 768px){
                                width: 38px;
                            }
                        }
                        
                        .arrow {
                            width: 14px;
                            @media (min-width: 0px) and (max-width: 767px){
                                display: none;
                            }
                            @media (min-width: 768px){
                                margin-left: 20px;
                            }
                        }

                        &:hover{
                            background: #464646;
                            .user-pic{
                                box-shadow: 0px 5px 10px black;
                            }
                        }

                    }
                }
            }
        }
    }

}

.mobile-bar {
    background: #303030;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #474747;

    .close-btn{
        padding: 15px;
        margin-right: 15px;
    }

    .title {
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
    }

    img {
        width: 20px;
        float: left;
    }

    @media(min-width: 1180px) {
        display: none;
    }
}