.thankyou-page {   
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    max-height: 650px;
    z-index: 99999;
    background: white;
    background-size: cover;
    background-position: center;
    .thankyou-title {
        color: #FF008A;
        font-size: 64px;
        margin: 48px 0px 23px 0px;
        line-height: 56px;
        font-weight: 700;
    }
    .thankyou-text {
        font-size: 24px;
        color: white;
        line-height: 36px;
        font-weight: 300;
        margin: 0px;
        margin-bottom: 31px;
    }
    .thankyou-trouble {
        font-size: 18px;
        color: white;
        line-height: 27px;
        font-weight: 400;
        margin: 0px;
        margin-bottom: 62px;
        a {
            color: #0094FF;
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 0px 30px;
        text-align: center;
        img {
            width: 90px;
        }
        .thankyou-title {
            font-size: 40px;
            margin: 20px 0px;
        }
        .thankyou-text {
            font-size: 20px;
            line-height: 20px;
            margin: 0px;
            margin-bottom: 25px;
        }
        .thankyou-trouble {
            font-size: 16px;
            margin-bottom: 40px;
        }
    }
}